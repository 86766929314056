.hero {
	background-position: center;
}

section#our-work {
	background: #FFF;
	padding: 4rem 1rem;
}

.work-wrap {
	max-width: $max-width;
	margin: auto;
}
