.orange {
	color: $orange;
}

.white {
	color: #fff;
}

.text-center {
	text-align: center;
}

.img-responsive {
	max-width: 100%;
	margin: auto;
	display: block;
}

/* GRID UTILITIES */

.container {
	max-width: $max-width;
	margin: 0 auto;
}

.row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	align-items: center;
}

.col {
	display: flex;
	flex-direction: column;
	flex-basis: 100%;
}

.double-col {
	display: flex;
	flex-direction: column;
	flex-basis: 100%;
}

/* SPACING UTILITIES */
.mb2 {
	margin-bottom: 2rem;
}
.mb3 {
	margin-bottom: 3rem;
}
.pt1 {
	padding-top: 1rem;
}
.pt3 {
	padding-top: 3rem;
}
.pt5 {
	padding-top: 5rem;
}
.pb1 {
	padding-bottom: 1rem;
}
.pb3 {
	padding-bottom: 3rem;
}
.pb5 {
	padding-bottom: 5rem;
}
.py1 {
	padding: 1rem 0;
}
.py3 {
	padding: 3rem 0;
}
.py5 {
	padding: 5rem 0;
}
.px1 {
	padding: 0 1rem;
}
.px3 {
	padding: 0 3rem;
}
.px5 {
	padding: 0 5rem;
}

@media screen and (min-width: 768px) {
	.col {
		flex: 1;
		padding: 0 1rem;
	}

	.row .col:first-child {
		padding-left: 0;
	}

	.row .col:last-child {
		padding-right: 0;
	}

	.double-col {
		flex: 2;
	}
}
