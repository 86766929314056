section#web {
	padding: 5rem 1rem;
    max-width: $max-width;
	margin: auto;
}

section#web-hosting {
	padding: 3rem 1rem;
    max-width: $max-width;
	margin: auto;
}

.card-container {
    max-width: 1200px;
    padding: 2rem 1rem 5rem 1rem;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.card {
    box-shadow: 0 0 4px #CCC;
    flex-grow: 1;
    width: 30%;
    margin: 0 1rem;
    padding: 1rem 1rem 4rem 1rem;
}

.price {
    vertical-align: top;
    font-weight: 700;
}
.dollar {
    font-size: 2rem;
    font-weight: 900;
}
.mo {
}

.text-center {
    text-align: center;
}

.card-text {
    line-height: 1.5;
}

.card-body {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    padding: 0 1.5rem 0 1.5rem;
}

.card-body li {
    font-size: .8rem;
    margin: .4rem 0;
}


@media screen and (max-width: 768px) {
	.card-container {
		flex-direction: column;
	}
    .card {
        width: 100%;
        margin: 1rem auto;
    }
}
