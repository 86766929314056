.service-section {
	background: #000;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 2rem 1rem;
	height: 600px;
	display: flex;
	border-top: 5px solid $orange;

	&:nth-of-type(3) {
		background-position: 70%;
	}
}

.service-section-wrap {
	width: $max-width;
	margin: auto;
}
