.btn {
	display: inline-block;
	text-align: center;
	text-decoration: none;
	font-weight: 900;
	font-size: 1em;
	text-transform: uppercase;
	color: #fff;
	padding: 1em 3em;
	color: white;
	border: 5px solid $orange;
	background: none;
	transition: 0.5s;
	cursor: pointer;
}

.btn:hover {
	background: $orange;
}

.ghost-btn {
	@extend .btn;
	border: 5px solid #fff;
	margin-top: 1.5rem;
	font-family: 'Montserrat', Sans-serif;
}
.ghost-btn:hover {
	background: #fff;
	color: $orange;
}

.reverse-btn {
	@extend .btn;
	margin-top: 1.5rem;
	font-family: 'Montserrat', Sans-serif;
	color: $orange;
}
.reverse-btn:hover {
	background: $orange;
	color: #FFF;
}