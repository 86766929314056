header {
	background: #000;
	padding: 0.5rem;
	position: fixed;
	width: 100%;
	z-index: 9999;
}

.header-wrap {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.logo {
		width: 250px;
		margin: 0.5rem 0 0.5rem 1rem;
	}
}

.menu-open {
	z-index: 999;
  }
  
  .menu-close,
  .menu-open {
	position: absolute;
	right: 15px;
	top: 15px;
	color: #FFF;
	padding: 0.8rem 1rem;
	background: $orange;
	display: none;
  }
  
  .main-nav {
	list-style: none;
	margin: 0;
	padding: 0;
  }
	.main-nav li {
	display: inline-block;
		a {
			color: #fff;
			text-decoration: none;
			padding: 1.2rem;
			display: block;
			font-weight: 700;
		}
		a:hover {
			color: $orange;
		}
		a.active,
		a.focus {
			color: $gray;
		}
	}

  .main-nav ul {
	position: absolute;
	display: none;
	background: $orange;
	padding: 0;
	margin: 0;
	width: 100px;
	transition: opacity 0.6s ease-in-out;
  }
  .main-nav ul li {
	display: block;
	text-align: left;
  }
  .main-nav li:hover > ul {
	display: block;
  }
  
  .close {
	right: -200px;
  }
  .open {
	right: 0px;
  }
  
  @media (max-width: 768px) {
	.menu-open,
	.menu-close {
	  display: block;
	}
  
	.main-nav-wrap {
	  width: 250px;
	  padding: 0;
	  margin: 0;
	  list-style: none;
	  background: #000;
	  position: absolute;
	  opacity: 1;
	  right: -250px;
	  top: 0;
	  height: 100vh;
	  overflow-x: visible;
	  z-index: 999;
	  transition: right 0.3s ease;
	}
  
	.main-nav {
	  text-align: left;
	  padding: 4rem 0 0 1rem;
	}
  
	.main-nav li {
	  display: block;
	}
  
	.main-nav ul {
	  position: relative;
	  margin-left: 10px;
	  display: block;
	}
  
	.close {
	  right: -250px;
	}
	.open {
	  right: 0px;
	}
  }