section#about {
	background: #FFF;
	padding: 5rem 1rem;
}

.about-wrap {
	max-width: $max-width;
	margin: auto;
}

section#team {
	background: $gray;
	text-align: center;
	padding: 3rem 1rem;
}

.team-wrap {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding: 0 2rem;
}

.team-bucket {
	text-align: center;
	margin: 2rem;
	flex: 1 1 0;
	.team-img {
		border-radius: 50%;
		border: 5px solid $orange;
		width: 150px;
    	-webkit-transition: all .5s ease-in-out;  
	}
	.team-img:hover{
		filter: brightness(1.2);
	}
}

.bio-wrap {
	max-width: 800px;
	margin: auto;
	padding: 3rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	.bio-title {
		font-size: 1.2rem;
	}
	.bio-img {
		border-radius: 50%;
		border: 10px solid $orange;
		width: 250px;
	}
}
