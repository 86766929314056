section#contact-form {
	background: #fbfafa;
	padding: 2rem 1rem 3rem 1rem;
	.btn {
		border: 5px solid $orange;
		margin-top: 1.5rem;
		color: $orange;
	}
	.btn:hover {
		background: $orange;
		color: #FFF;
	}
}

.contact {
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
}

.contact form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
	margin-top: 3rem;
}

.contact #main-form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
}

.contact form label {
	display: block;
	margin-bottom: 5px;
	font-weight: 400;
}

.contact form p {
	margin: 0;
}

.contact form .full {
	grid-column: 1 / 3;
}

.contact form select,
.contact form input,
.contact form textarea {
	width: 100%;
	padding: 0.8rem;
	border: 3px solid $gray;
	font-size: 1rem;
}

.contact form button {
	border: none;
	cursor: pointer;
}

/* class applies to select element itself, not a wrapper element */
.select-css {
	display: block;
	font-size: 16px;
	font-family: sans-serif;
	font-weight: 700;
	color: #444;
	line-height: 1.3;
	padding: 0.6em 1.4em 0.5em 0.8em;
	width: 100%;
	max-width: 100%; /* useful when width is set to anything other than 100% */
	box-sizing: border-box;
	margin: 0;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
	border-radius: 0;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	/* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
      for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
      
  */
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23ff3c00%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
		linear-gradient(to bottom, #fff 0%, #fff 100%);
	background-repeat: no-repeat, repeat;
	/* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
	background-position: right 0.7em top 50%, 0 0;
	/* icon size, then gradient */
	background-size: 0.65em auto, 100%;
}
/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
	display: none;
}

/* Disabled styles */
.select-css:disabled,
.select-css[aria-disabled='true'] {
	color: graytext;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
		linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
}

section#accreditation {
	background: $gray;
	padding: 3rem 1rem 2rem 1rem;
	.accreditation-wrap {
		max-width: $max-width;
		margin: 0 auto;
		.col {
			align-items: center;
		}
		.cwa {
			width: 200px;
		}
		.usa {
			width: 200px;
		}
	}
}

footer {
	text-align: center;
	padding: 4rem 0;
	//background: linear-gradient(0deg, #000 10%, $electricblue 100%);
	background: #000;
	p {
		color: #fff;
		line-height: 1.5rem;
	}
	.fist {
		width: 40px;
	}
	.social {
		margin: 0 0 2rem 0;
	}
	.social a {
		margin: 1rem;
		color: #fff;
		transition: all 300ms ease;
	}
	.social a:hover {
		color: $orange;
	}
}

/* LARGE SCREENS */
@media (min-width: 700px) {
	.wrapper {
		display: grid;
		grid-template-columns: 1fr 2fr;
	}
}
