::selection {
	background-color: $orange;
}

html {
	box-sizing: border-box;
	//scroll-behavior: smooth;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	border: 0;
	padding: 0;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	font-family: 'Montserrat', Sans-serif;
	font-size: 1rem;
	color: #000;
}

main {
	position: relative;
}

li,
ol {
	line-height: 2;
}

a {
	color: $orange;
}

.hero {
	height: 700px;
	background-color: $gray;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: left;
	overflow: hidden;
	.hero-wrap {
		max-width: $max-width;
		margin: auto;
		padding: 0 1rem;
	}
}

section {
	overflow: hidden;
}

.footer-logo{
	width: 300px;
}

@media screen and (min-width: 992px) {
	.hero {
		.hero-wrap {
			max-width: none;
			width: 1200px;
		}
	}
}
