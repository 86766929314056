h1 {
	//@include font-size(4rem);
	font-size: 3rem;
	font-size: clamp(3rem, 4vw, 4rem);
	font-weight: 900;
	text-transform: uppercase;
	color: #fff;
	line-height: 1;
	margin: 0;
	.homepage-hero &,
	.hero-wrap & {
		text-shadow: 1px 1px 5px #000;
	}
}

h2 {
	font-size: 2rem;
	font-weight: 700;
	text-transform: uppercase;
	color: #fff;
	margin: 0;
	.homepage-hero &,
	.hero-wrap & {
		text-shadow: 1px 1px 5px #000;
	}
}

h3 {
	font-size: 2.15rem;
	font-weight: 900;
	margin: 1rem 0 0 0;
}

h4 {
	font-size: 1.1rem;
	font-weight: 700;
	letter-spacing: 0.625rem;
	text-transform: uppercase;
}

h5 {
	font-size: 1.1rem;
	font-weight: 900;
	text-transform: uppercase;
}

p {
	line-height: 1.5;
}

.text-lead {
	font-size: 1.25rem;
	font-weight: 400;
}

.text-small {
	font-size: 0.825rem;
	font-weight: 400;
}
