.homepage-hero {
	height: 600px;
	background-color: $gray;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	h1 {
		// text-shadow: 1px 1px 5px #000;
		margin: 0;
	}
	h2 {
		// text-shadow: 1px 1px 5px #000;
		margin: 0.3rem;
	}
}

section#services {
	background-color: #000;
	padding: 2rem 0;
	text-align: center;
	.services-wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		max-width: $max-width;
		margin: 2rem auto;
	}
	.service-bucket {
		text-align: center;
		color: #fff;
		padding: 1rem;
	}
	h3 {
		font-size: 1.15rem;
	}
	.icon-bubble {
		background: $orange;
		// padding: 1rem;
		border-radius: 50%;
		height: 4rem;
		width: 4rem;
		display: flex;
		margin: 0 auto;
		justify-content: center;
		align-items: center;
	}
}

section#intro {
	padding: 4rem 1rem;
	.intro-wrap {
		max-width: $max-width;
		margin: auto;
		padding: 3rem;
		border: 10px solid $orange;
	}
	h2 {
		margin: 0;
	}
}

section#unions {
	background-color: #000;
	padding: 2rem 1rem;
	text-align: center;
}

@media screen and (min-width: 600px) {
	.service-bucket {
		flex: 1 0 50%;
	}
}

@media screen and (min-width: 992px) {
	.service-bucket {
		flex: 1 0 25%;
	}
}
