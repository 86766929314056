section#legend-intro {
	padding: 2rem 1rem;
	background-color: #000;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: right;
	height: 400px;
	display: flex;
}

section#legend {
	padding: 5rem 1rem;
}

.legend-wrap {
	width: $max-width;
	margin: auto;
}

.legend-content {
	width: 500px;
}

section#unionhistories {
	background: #000;
	padding: 2rem 1rem;
}

.uh-wrap {
	max-width: 1000px;
	margin: auto;
	display: flex;
	align-items: center;
}

.legend-cta{
	background-color: $orange;
	text-align: center;
	padding: 3rem 1rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.legend-cta-content{
		max-width: 800px;
		margin: auto;
		text-align: center;
	}
}

section#video {
	padding: 2rem 1rem;
	text-align: center;
}

.video-wrap {
	max-width: 1000px;
	margin: 2rem auto;
}
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

@media screen and (max-width: 767px) {
	/*
	section#legend-intro:after {
		content: '';
		background-color: #000;
		opacity: 0.5;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 400px;
		z-index: 1;
	} */
	.legend-content {
		width: auto;
	}
}

section#legend-intro > * {
	z-index: 100;
}
