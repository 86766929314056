@import 'variables';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;900&display=swap');

@import 'globals';
@import 'utilities';
@import 'typography';
@import 'header';
@import 'footer';
@import 'homepage';
@import 'buttons';
@import 'services';
@import 'about';
@import 'our-work';
@import 'contact';
@import 'video';
@import 'web';
@import 'digital-marketing';
/*@import '../../node_modules/animate.css/animate.min';*/
/*@import 'animations';
@import '../../node_modules/rfs/scss';
@import 'hero';
@import 'portfolio';
@import 'about';
@import 'products';
@import 'blog';
@import 'contact'; */
